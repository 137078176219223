<template>
  <!--门店预订单 -->
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
  </div>
</template>

<script>
import TablePage from "@/components/tablePage"; //表格页面组件
import {
  listOrder,
  listSummary,
  copyShopPreOrder,
} from "@/api/shop/bill/reserveOrder"; //门店预订单
import { updateInstoreStatus } from "@/api/purchase/index";
export default {
  name: "reserveOrder",
  components: { TablePage },
  dicts: ["bill_status"],
  data() {
    return {
      dialogOptions: {},
      options: {
        uuid: "ad0674b7-54ec-23c5-6e89-b4378c0e5e9e",
        mutiSelect: true, // 多选
        loading: false,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        exportOption: {
          show: true,
          exportApi: "exportOrderList",
          exportName: "预订单门店表",
          timeout: 60 * 5 * 1880,
        },
        title: "门店预订单列表",
        getListApi: listOrder,
        getSummaryApi: listSummary,
        getDataKey: (res) => {
          const list = res?.rows?.map(item => ({ ...item, children: null }))
          return {
            list,
            total: res?.total,
          };
        },
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "billId",
        //搜索
        search: [
          {
            label: "单据时间",
            type: "datetimerange",
            model: "",
            noReset: true,
            filterFnc: ([a, b] = []) => ({
              beginBillDate: a,
              endBillDate: b,
            }),
          },
          {
            keyIndex: 0,
            format: "datetimerange",
            type: "timeRange",
            defaultValue: "month",
            data: ["day", "yesterday", "week", "lastWeek", "month", "lastMonth"],
          },
           {
            type: "filters",
            tip: "全部/单据编号",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "billNos", label: "单据编号" }
            ]
          },
          {
            type: "date",
            label: "取货日期",
            model: "",
            filter: "arrivalDate",
            seniorSearch: true,
          },
          {
            type: "local",
            label: "状态",
            model: "",
            filter: "orderStatuss",
            seniorSearch: true,
            option: {
              data: [
                { label: "全部", value: "1" },
                { label: "未接单", value: "2" },
                { label: "待配送", value: "3" },
                { label: "待自提", value: "4" },
                { label: "已取货", value: "5" },
                { label: "已完成", value: "6" },
                { label: "待退款", value: "10" },
                { label: "已退货", value: "7" },
                { label: "已拒单", value: "9" },
              ],
              value: "value",
              label: "label",
              multiple: true,
            },
          },
          this.$select({
            key: "listShop",
            option: {
              filter: "orderShopIds",
              seniorSearch: true,
              label: "预定门店",
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "listShop",
            option: {
              filter: "pickUpStoreIds",
              seniorSearch: true,
              label: "取货门店",
              option: {
                multiple: true,
              },
            },
          }),
          this.$select({
            key: "fetch_type",
            option: {
              filter: "fetchTypes",
              seniorSearch: true,
              option: {
                multiple: true,
              },
            },
          }),
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          {
            type: "button",
            tip: "高级查询",
            btnType: "primary",
            click: "seniorSearch",
          },
          { type: "button", tip: "重置", click: "reset" },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
          },
          {
            click: "audit",
            label: "审核",
            alertText: "确认要审核选中单据吗？",
            type: "success",
            icon: "el-icon-document",
          },
          {
            click: "stop",
            label: "终止",
            alertText: "确认要终止选中单据吗？",
            icon: "el-icon-remove-outline",
            type: "primary",
          },
          {
            label: "打印",
            type: "primary",
            btnType: "dropdown",
            print: {},
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            alertText: "确认要删除选中单据吗？",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "billNo",
            label: "单据编号",
            type: "link",
            click: "routerLink",
            minWidth: 180,
            align: "center",
          },
          {
            prop: "billDate",
            label: "单据时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "orderStatusName",
            label: "单据状态",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "orderInnerStatusName",
            label: "完成状态",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "factProduceDeptNames",
            label: "实际生产部门",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "refundStatusName",
            label: "退款状态",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "orderTypeName",
            label: "订单类型",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "orderQty",
            label: "单据数量",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "billFactMoney",
            label: "单据金额(元)",
            minWidth: 100,
            align: "center",
          },
          {
            prop: "orderShopName",
            label: "预定门店",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "fetchShopName",
            label: "取货/配送门店",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "receiverName",
            label: "客户名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "receiverPhone",
            label: "联系电话",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "fetchTypeName",
            label: "取货方式",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "produceDeptName",
            label: "生产部门",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditBy",
            label: "审核人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "auditTime",
            label: "审核时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 155,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 155,
            align: "center",
          },
        ],
        summary: ['orderQty', "billFactMoney"],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      const selectData = this.options.check;
      let url = "/api/system/shop/bill/preOrder/updateBillStatus";
      let billType = "140314";
      let pathName = "ReserveOrderDetail";
      switch (type) {
        case "routerLink":
          {
            const billId = row.billId;
            this.$router.push({
              name: pathName,
              query: {
                billId: billId,
                type: "Update",
              },
            });
          }
          break;
        case "add":
          {
            this.$router.push({
              name: pathName,
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "audit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("审核", selectData, url, billType);
          }
          break;
        case "redoAudit":
          {
            if (!selectData.length) return;
            this.handleBillStatus("反审核", selectData, url, billType);
          }
          break;
        case "del":
          {
            if (!selectData.length) return;
            this.handleBillStatus("删除", selectData, url, billType);
          }
          break;
        case "stop":
          {
            if (!selectData.length) return;
            this.handleBillStatus("关闭", selectData, url, billType);
          }
          break;
        case "copy":
          {
            if (!selectData.length) return;
            const billIds = selectData.map((i) => i.billId);
            copyShopPreOrder({ billIds }).then((res) => {
              this.$message.success("复制成功");
              this.$nextTick(() => {
                this.options.check = [];
              });
              this.$refs.tablePage.getList();
            });
          }
          break;
        default:
          break;
      }
    },
    handleBillStatus(command, selectData, url, BillType, type) {
      let billData = []; //过滤的数据
      let name = ""; //要执行的状态名字
      let tag = ""; //提示的状态
      let billStatus = ""; //要执行的状态数字
      let billId = [];
      let billNo = selectData.map((i) => i.billNo);
      if (command === "审核") {
        name = "审核";
        tag = "未审核";
        billStatus = "2";
        billId = selectData
          .filter((item) => item.orderStatus == 1)
          .map((i) => i.billId);
        if (billId.length === 0)
          return this.$message.error("勾选单据中没有未审核的单据哦~");
      } else if (command === "删除") {
        name = "删除";
        tag = "未审核";
        billStatus = "3";
        billId = selectData
          .filter((item) => item.orderStatus == 1)
          .map((i) => i.billId);
        if (billId.length === 0)
          return this.$message.error("勾选单据中没有未审核的单据哦~");
      } else if (command === "关闭") {
        name = "关闭";
        tag = "未审核";
        billStatus = "4";
        billId = selectData
          .filter((item) => ["5", "6", "7", 5, 6, 7].includes(item.orderStatus))
          .map((i) => i.billId);
        if (billId.length === 0)
          return this.$message.error("勾选单据中没有可以终止的单据哦~");
      }
      updateInstoreStatus([...billId], billStatus, url, BillType).then(() => {
        this.$nextTick(() => {
          this.options.check = [];
        });
        this.$modal.msgSuccess(`${name}成功`);
        setTimeout(() => {
          this.$refs.tablePage.getList();
        }, 500);
      });
    }
  },
};
</script>
