import { render, staticRenderFns } from "./index copy 2.vue?vue&type=template&id=9951aba6&scoped=true"
import script from "./index copy 2.vue?vue&type=script&lang=js"
export * from "./index copy 2.vue?vue&type=script&lang=js"
import style0 from "./index copy 2.vue?vue&type=style&index=0&id=9951aba6&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9951aba6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9951aba6')) {
      api.createRecord('9951aba6', component.options)
    } else {
      api.reload('9951aba6', component.options)
    }
    module.hot.accept("./index copy 2.vue?vue&type=template&id=9951aba6&scoped=true", function () {
      api.rerender('9951aba6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/shop/bill/reserveOrder/reserveOrderDetail/index copy 2.vue"
export default component.exports