var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.form, rules: _vm.rules, "label-width": "110px" },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "wrap",
          attrs: { id: "wrap" },
        },
        [
          _c(
            "topOperatingButton",
            {
              attrs: {
                disabled: _vm.disabled,
                isExamineBtn: true,
                showAudit: _vm.showAudit,
                isAddBtn: _vm.showAdd,
                id: "topOperatingButton",
              },
              on: {
                submitForm: _vm.submitForm,
                addBill: _vm.addBill,
                auditBill: _vm.auditBill,
                handleAdd: _vm.handleAdd,
                getQuit: _vm.getQuit,
              },
            },
            [
              _c(
                "template",
                { slot: "specialDiyBtn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", disabled: _vm.single },
                      on: {
                        click: function ($event) {
                          return _vm.auditBill("终止")
                        },
                      },
                    },
                    [_vm._v("终止 ")]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                billStatus: _vm.form.billStatus,
                id: "basicInformation",
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-w marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", prop: "billNo" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "单据编号",
                          },
                          model: {
                            value: _vm.form.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billNo", $$v)
                            },
                            expression: "form.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据时间", prop: "billDate" } },
                      [
                        _c("el-date-picker", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            disabled: "",
                            size: "mini",
                            type: "datetime",
                            placeholder: "单据时间",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                          },
                          model: {
                            value: _vm.form.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billDate", $$v)
                            },
                            expression: "form.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "提货方式", prop: "fetchType" } },
                      [
                        _c("SelectLocal", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            disabled: _vm.disabled,
                            option: {
                              remote: _vm.getDicts,
                              remoteBody: "fetch_type",
                              value: "dictValue",
                              label: "dictLabel",
                            },
                          },
                          model: {
                            value: _vm.form.fetchType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fetchType", $$v)
                            },
                            expression: "form.fetchType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "客户名称", prop: "receiverName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            size: "mini",
                            placeholder: "客户名称",
                            maxlength: "40",
                          },
                          model: {
                            value: _vm.form.receiverName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "receiverName", $$v)
                            },
                            expression: "form.receiverName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "联系电话", prop: "receiverPhone" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            maxlength: "20",
                            disabled: _vm.disabled,
                            size: "mini",
                            placeholder: "联系电话",
                          },
                          model: {
                            value: _vm.form.receiverPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "receiverPhone", _vm._n($$v))
                            },
                            expression: "form.receiverPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.form.fetchType != 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: { label: "地址", prop: "receiverRegionId" },
                          },
                          [
                            _c("el-cascader", {
                              ref: "cascader",
                              staticClass: "provinceListClass",
                              staticStyle: { width: "250px" },
                              attrs: {
                                disabled: _vm.disabled,
                                options: _vm.provinceList,
                                props: _vm.defaultProp,
                                maxlength: "80",
                                size: "mini",
                                filterable: "",
                                clearable: "",
                              },
                              on: { change: _vm.getNodes },
                              model: {
                                value: _vm.form.receiverRegionId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "receiverRegionId", $$v)
                                },
                                expression: "form.receiverRegionId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.fetchType != 1
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "详细地址",
                              prop: "receiverRegionAddress",
                            },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidth",
                              attrs: {
                                disabled: _vm.disabled,
                                size: "mini",
                                placeholder: "详细地址",
                              },
                              model: {
                                value: _vm.form.receiverRegionAddress,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.form,
                                    "receiverRegionAddress",
                                    $$v
                                  )
                                },
                                expression: "form.receiverRegionAddress",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-form-item",
                      { attrs: { label: "预定门店", prop: "orderShopId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listShop",
                              option: {
                                option: {
                                  disabled: _vm.disabled,
                                },
                              },
                            }).option,
                          },
                          on: { selectChange: _vm.selectChangeOrderShop },
                          model: {
                            value: _vm.form.orderShopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "orderShopId", $$v)
                            },
                            expression: "form.orderShopId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "取货/配送门店", prop: "fetchShopId" },
                      },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "listShop",
                              option: {
                                option: {
                                  disabled: _vm.disabled,
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.form.fetchShopId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "fetchShopId", $$v)
                            },
                            expression: "form.fetchShopId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "生产部门", prop: "produceDeptId" } },
                      [
                        _c("SelectLocal", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            disabled: _vm.disabled,
                            option: {
                              data: _vm.produceSelectList,
                              value: "decorationRoomId",
                              label: "decorationRoomName",
                            },
                          },
                          model: {
                            value: _vm.form.produceDeptId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "produceDeptId", $$v)
                            },
                            expression: "form.produceDeptId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _vm.form.fetchType != 3
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "取货/配送时间",
                                  prop: "fetchDate",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "130px" },
                                  attrs: {
                                    size: "mini",
                                    type: "date",
                                    placeholder: "取货时间",
                                    disabled: _vm.disabled,
                                    "value-format": "yyyy-MM-dd",
                                    "picker-options": _vm.forbiddenTime,
                                  },
                                  model: {
                                    value: _vm.form.fetchDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "fetchDate", $$v)
                                    },
                                    expression: "form.fetchDate",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.form.fetchType != 3
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "fetchTime",
                                  "label-width": "0",
                                },
                              },
                              [
                                _c("SelectLocal", {
                                  staticStyle: { width: "120px" },
                                  attrs: {
                                    disabled: _vm.disabled,
                                    option: {
                                      data: _vm.fetchTimeList,
                                      value: "value",
                                      label: "value",
                                    },
                                  },
                                  model: {
                                    value: _vm.form.fetchTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "fetchTime", $$v)
                                    },
                                    expression: "form.fetchTime",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "手工单据号" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            size: "mini",
                            placeholder: "请输入手工单据号",
                            maxlength: 40,
                          },
                          model: {
                            value: _vm.form.handBillNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "handBillNo", $$v)
                            },
                            expression: "form.handBillNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "磅数" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            size: "mini",
                            placeholder: "请输入磅数",
                            maxlength: 40,
                          },
                          model: {
                            value: _vm.form.handPointSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "handPointSize", $$v)
                            },
                            expression: "form.handPointSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "尺寸" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            size: "mini",
                            placeholder: "请输入尺寸",
                            maxlength: 40,
                          },
                          model: {
                            value: _vm.form.handSize,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "handSize", $$v)
                            },
                            expression: "form.handSize",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "样式" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            size: "mini",
                            placeholder: "请输入样式",
                            maxlength: 40,
                          },
                          model: {
                            value: _vm.form.handStyle,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "handStyle", $$v)
                            },
                            expression: "form.handStyle",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "会员", prop: "vipId" } },
                      [
                        _c("SelectRemote", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            option: _vm.$select({
                              key: "vip",
                              option: {
                                option: {
                                  disabled: _vm.disabled,
                                  clearable: true,
                                  showItem: [
                                    {
                                      vipId: _vm.form.vipId,
                                      vipName: _vm.form.vipName,
                                    },
                                  ],
                                },
                              },
                            }).option,
                          },
                          model: {
                            value: _vm.form.vipId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "vipId", $$v)
                            },
                            expression: "form.vipId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "收款方式", prop: "payModeId" } },
                      [
                        _c("SelectLocal", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            disabled: _vm.disabled,
                            option: {
                              data: _vm.playList,
                              value: "payModeId",
                              label: "payModeName",
                            },
                          },
                          model: {
                            value: _vm.form.payModeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "payModeId", $$v)
                            },
                            expression: "form.payModeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "收款金额" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            size: "mini",
                            placeholder: "收款金额",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.limitInputlength(
                                _vm.form.billPayMoney,
                                _vm.form,
                                "billPayMoney"
                              )
                            },
                          },
                          model: {
                            value: _vm.form.billPayMoney,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billPayMoney", $$v)
                            },
                            expression: "form.billPayMoney",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据备注", prop: "remark" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 1 },
                          },
                          model: {
                            value: _vm.form.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "remark", $$v)
                            },
                            expression: "form.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "商品信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: _vm.form.billDetailItems,
                          border: "",
                          "show-summary": "",
                          "summary-method": _vm.getSummaries,
                          "max-height": _vm.tableHeight,
                          height: _vm.tableHeight,
                        },
                        on: {
                          "cell-mouse-enter": _vm.cellMouseEnter,
                          "cell-mouse-leave": _vm.cellMouseLeave,
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: _vm.tableCellLabel,
                            width: "80",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-circle-plus operatePush",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row("push", scope.$index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  scope.row.hoverRow
                                    ? _c("i", {
                                        staticClass:
                                          "el-icon-remove operateDel",
                                        attrs: { disabled: _vm.disabled },
                                        on: {
                                          click: function ($event) {
                                            return _vm.row("del", scope.$index)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                  !scope.row.hoverRow
                                    ? _c("div", [
                                        _vm._v(_vm._s(scope.$index + 1)),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品编码",
                            width: "160",
                            align: "center",
                            prop: "goodsNo",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("SelectRemote", {
                                    ref: "goodsNo" + "_" + scope.$index + "_1",
                                    attrs: {
                                      size: "mini",
                                      disabled:
                                        _vm.disabled || _vm.form.orderType == 1,
                                      option: _vm.$select({
                                        key: "listGoods",
                                        noEcho: true,
                                        option: {
                                          option: {
                                            noEcho: true,
                                            label: "goodsNo",
                                            remoteBody: {
                                              shopId: _vm.form.orderShopId,
                                            },
                                            showItem: [
                                              {
                                                goodsId: scope.row.goodsId,
                                                goodsName: scope.row.goodsNo,
                                              },
                                            ],
                                            change: function (propValue, row) {
                                              if (propValue) {
                                                var findIndex =
                                                  _vm.form.billDetailItems.findIndex(
                                                    function (item) {
                                                      return (
                                                        item.goodsNo ===
                                                        propValue
                                                      )
                                                    }
                                                  )
                                                var rows = JSON.parse(
                                                  JSON.stringify(row)
                                                )
                                                rows.findIndex = findIndex
                                                _vm.getGoodS([rows])
                                              }
                                            },
                                            tableChange: function (
                                              propValue,
                                              row
                                            ) {
                                              _vm.getGoodS(row.check)
                                            },
                                            buttons: [
                                              {
                                                type: "more",
                                                option: {
                                                  title: "选择商品",
                                                  width: 1250,
                                                  type: "TreeAndTable",
                                                  formData: _vm.$dialog({
                                                    key: "goods",
                                                    option: {
                                                      queryParams: {
                                                        shopId:
                                                          _vm.form.orderShopId,
                                                      },
                                                      table: Object.assign(
                                                        {},
                                                        _vm.$dialog({
                                                          key: "goods",
                                                        }).table
                                                      ),
                                                    },
                                                  }),
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      }).option,
                                      filterable: true,
                                      placeholder: "选择商品",
                                    },
                                    on: {
                                      handleSelectKeydown: function ($event) {
                                        return _vm.handleTableKeydown(
                                          $event,
                                          scope.row,
                                          "goodsNo",
                                          scope.$index,
                                          1
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.goodsNo,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "goodsNo", $$v)
                                      },
                                      expression: "scope.row.goodsNo",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "商品名称",
                            align: "center",
                            prop: "goodsName",
                            width: "180",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "条码",
                            width: "180",
                            align: "center",
                            prop: "barcode",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "规格",
                            align: "center",
                            prop: "goodsSpec",
                            width: "100",
                          },
                        }),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "单位",
                              align: "center",
                              prop: "unitName",
                              width: "120",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "billDetailItems." +
                                            scope.$index +
                                            ".unitId",
                                          rules:
                                            _vm.rules["billDetailItems.unitId"],
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            ref:
                                              "unitName" +
                                              "_" +
                                              scope.$index +
                                              "_5",
                                            attrs: {
                                              size: "mini",
                                              disabled:
                                                _vm.disabled ||
                                                _vm.form.orderType == 1,
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.unitSalePriceChange(
                                                  $event,
                                                  scope.row,
                                                  140314
                                                )
                                              },
                                            },
                                            nativeOn: {
                                              keydown: function ($event) {
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitName",
                                                  scope.$index,
                                                  5
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.unitId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "unitId",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.unitId",
                                            },
                                          },
                                          _vm._l(
                                            scope.row.units,
                                            function (item) {
                                              return _c("el-option", {
                                                key: item.unitId,
                                                attrs: {
                                                  label: item.unitName,
                                                  value: item.unitId,
                                                },
                                              })
                                            }
                                          ),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("单位")]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "加值属性",
                              align: "center",
                              prop: "machining",
                              width: "180",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "x-bc" },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              disabled: _vm.disabled,
                                              type: "primary",
                                              size: "mini",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openMachining(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("选择")]
                                        ),
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: scope.row.machining,
                                              placement: "top",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "omit1" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      scope.row.machining
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("加值属性")]),
                            ]),
                          ],
                          2
                        ),
                        _c(
                          "el-table-column",
                          {
                            attrs: {
                              label: "数量",
                              align: "center",
                              width: "120",
                              prop: "unitQty",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop:
                                            "billDetailItems." +
                                            scope.$index +
                                            ".unitQty",
                                          rules:
                                            _vm.rules[
                                              "billDetailItems.unitQty"
                                            ],
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          ref:
                                            "unitQty" +
                                            "_" +
                                            scope.$index +
                                            "_6",
                                          attrs: {
                                            size: "mini",
                                            type: "number",
                                            disabled: _vm.disabled,
                                            maxlength: "7",
                                            sunyunFormat: function (v) {
                                              return _vm.$syInput(1, v)
                                            },
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.limitInputlength(
                                                scope.row.unitQty,
                                                scope.row,
                                                "unitQty",
                                                true
                                              )
                                            },
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              return _vm.onkeyupUnitQty(
                                                scope.row
                                              )
                                            },
                                            keydown: [
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "up",
                                                    38,
                                                    $event.key,
                                                    ["Up", "ArrowUp"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.$index,
                                                  6
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "down",
                                                    40,
                                                    $event.key,
                                                    ["Down", "ArrowDown"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.$index,
                                                  6
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "left",
                                                    37,
                                                    $event.key,
                                                    ["Left", "ArrowLeft"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 0
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.$index,
                                                  6
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "right",
                                                    39,
                                                    $event.key,
                                                    ["Right", "ArrowRight"]
                                                  )
                                                ) {
                                                  return null
                                                }
                                                if (
                                                  "button" in $event &&
                                                  $event.button !== 2
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.$index,
                                                  6
                                                )
                                              },
                                              function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.preventDefault()
                                                return _vm.handleTableKeydown(
                                                  $event,
                                                  scope.row,
                                                  "unitQty",
                                                  scope.$index,
                                                  6
                                                )
                                              },
                                            ],
                                          },
                                          model: {
                                            value: scope.row.unitQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "unitQty",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.unitQty",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c("template", { slot: "header" }, [
                              _c("i", { staticStyle: { color: "#ff4949" } }, [
                                _vm._v("* "),
                              ]),
                              _c("span", [_vm._v("数量")]),
                            ]),
                          ],
                          2
                        ),
                        _c("el-table-column", {
                          attrs: {
                            label: "原价",
                            align: "center",
                            prop: "unitOldPrice",
                            width: "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "原价金额",
                            align: "center",
                            prop: "unitOldMoney",
                            width: "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "单价(元)",
                            align: "center",
                            width: "120",
                            prop: "unitPrice",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: scope.$index.unitPrice } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          size: "mini",
                                          type: "number",
                                          disabled: _vm.disabled,
                                          maxlength: "7",
                                          sunyunFormat: function (v) {
                                            return _vm.$syInput(1, v)
                                          },
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.limitInputlength(
                                              scope.row.unitPrice,
                                              scope.row,
                                              "unitPrice",
                                              true
                                            )
                                          },
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            return _vm.onkeyupUnitQty(scope.row)
                                          },
                                        },
                                        model: {
                                          value: scope.row.unitPrice,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              scope.row,
                                              "unitPrice",
                                              $$v
                                            )
                                          },
                                          expression: "scope.row.unitPrice",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "金额(元)",
                            align: "center",
                            width: "120",
                            prop: "unitMoney",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$syInput(3, scope.row.unitMoney)
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "备注",
                            align: "center",
                            prop: "remark",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c("el-input", {
                                    ref: "remark" + "_" + scope.$index + "_9",
                                    attrs: {
                                      size: "mini",
                                      disabled: _vm.disabled,
                                      maxlength: "8",
                                    },
                                    nativeOn: {
                                      keydown: function ($event) {
                                        return _vm.handleTableKeydown(
                                          $event,
                                          scope.row,
                                          "remark",
                                          scope.$index,
                                          9
                                        )
                                      },
                                    },
                                    model: {
                                      value: scope.row.remark,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "remark", $$v)
                                      },
                                      expression: "scope.row.remark",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: {
          title: "选择加值属性",
          percentageWidth: "60%",
          showDialog: _vm.dialogFormVisible,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogFormVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", [
                  _c("div", [_vm._v("选择规格")]),
                  _c(
                    "div",
                    {
                      staticClass: "x-w",
                      staticStyle: { "margin-bottom": "20px" },
                    },
                    _vm._l(_vm.machiningRow.units, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "cursorP itemMachining",
                          class: { activeMachining: _vm.activeIndex == index },
                          on: {
                            click: function ($event) {
                              return _vm.goodsSpecChange(index)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                item.goodsSpec +
                                  " " +
                                  (item.salePrice > 0
                                    ? "￥" + item.salePrice
                                    : "")
                              ) +
                              " "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    _vm._l(
                      _vm.baseGoodsMachiningGroups,
                      function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticStyle: { "margin-bottom": "20px" },
                          },
                          [
                            _c(
                              "div",
                              { staticStyle: { "margin-bottom": "10px" } },
                              [
                                _vm._v(
                                  " " + _vm._s(item.machiningGroupName) + " "
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "x-w",
                                staticStyle: { "margin-bottom": "20px" },
                              },
                              _vm._l(
                                item.machiningItems,
                                function (item2, index2) {
                                  return _c(
                                    "div",
                                    {
                                      key: index2,
                                      staticClass: "cursorP itemMachining",
                                      class: {
                                        activeMachining:
                                          _vm.hasMachiningId(
                                            item2.machiningId,
                                            _vm.radioIds
                                          ) ||
                                          _vm.hasMachiningId(
                                            item2.machiningId,
                                            _vm.multipleMachiningIds
                                          ),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.combinationCheckClick(
                                            item2,
                                            index,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item2.machiningName +
                                              " " +
                                              (item2.machiningPrice > 0
                                                ? "￥" + item2.machiningPrice
                                                : "")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.resetMach } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitMach } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }