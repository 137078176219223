<template>
  <el-form ref="form" :model="form" :rules="rules" label-width="110px">
    <div class="wrap" v-loading="loading" id="wrap">
      <topOperatingButton :disabled="disabled" :isExamineBtn="true" :showAudit="showAudit" :isAddBtn="showAdd"
        @submitForm="submitForm" @addBill="addBill" @auditBill="auditBill" @handleAdd="handleAdd" @getQuit="getQuit"
        id="topOperatingButton">
        <template slot="specialDiyBtn">
          <el-button size="mini" :disabled="single" @click="auditBill('终止')">终止
          </el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom cardTitle="基本信息" :billStatus="form.billStatus" id="basicInformation">
        <template slot="cardContent">
          <div class="x-w marT10">
            <el-form-item label="单据编号" prop="billNo">
              <el-input class="inputWidth" size="mini" :disabled="true" v-model="form.billNo"
                placeholder="单据编号"></el-input>
            </el-form-item>
            <el-form-item label="单据时间" prop="billDate">
              <el-date-picker disabled style="width: 250px" size="mini" v-model="form.billDate" type="datetime"
                placeholder="单据时间" value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="提货方式" prop="fetchType">
              <SelectLocal :disabled="disabled" v-model="form.fetchType" style="width: 250px" :option="{
                  remote: getDicts,
                  remoteBody: 'fetch_type',
                  value: 'dictValue',
                  label: 'dictLabel',
                }" />
            </el-form-item>
            <el-form-item label="客户名称" prop="receiverName">
              <el-input :disabled="disabled" class="inputWidth" size="mini" v-model="form.receiverName"
                placeholder="客户名称" maxlength="40"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="receiverPhone">
              <el-input maxlength="20" :disabled="disabled" class="inputWidth" size="mini"
                v-model.number="form.receiverPhone" placeholder="联系电话"></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="receiverRegionId" v-if="form.fetchType != 1">
              <el-cascader :disabled="disabled" style="width: 250px" ref="cascader" class="provinceListClass"
                :options="provinceList" :props="defaultProp" v-model="form.receiverRegionId" maxlength="80" size="mini"
                filterable clearable @change="getNodes">
              </el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="receiverRegionAddress" v-if="form.fetchType != 1">
              <el-input :disabled="disabled" class="inputWidth" size="mini" v-model="form.receiverRegionAddress"
                placeholder="详细地址"></el-input>
            </el-form-item>
            <el-form-item label="预定门店" prop="orderShopId">
              <SelectRemote @selectChange="selectChangeOrderShop" style="width: 250px" v-model="form.orderShopId"
                :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        disabled: disabled,
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="取货/配送门店" prop="fetchShopId">
              <SelectRemote style="width: 250px" v-model="form.fetchShopId" :option="
                  $select({
                    key: 'listShop',
                    option: {
                      option: {
                        disabled: disabled,
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="生产部门" prop="produceDeptId">
              <SelectLocal :disabled="disabled" v-model="form.produceDeptId" style="width: 250px" :option="{
                  data: produceSelectList,
                  value: 'decorationRoomId',
                  label: 'decorationRoomName',
                }" />
            </el-form-item>
            <div class="x-x">
              <el-form-item label="取货/配送时间" prop="fetchDate" v-if="form.fetchType != 3">
                <el-date-picker style="width: 130px" size="mini" v-model="form.fetchDate" type="date" placeholder="取货时间"
                  :disabled="disabled" value-format="yyyy-MM-dd" :picker-options="forbiddenTime">
                </el-date-picker>
              </el-form-item>
              <el-form-item prop="fetchTime" v-if="form.fetchType != 3" label-width="0">
                <SelectLocal :disabled="disabled" v-model="form.fetchTime" style="width: 120px" :option="{
                    data: fetchTimeList,
                    value: 'value',
                    label: 'value',
                  }" />
              </el-form-item>
            </div>
            <el-form-item label="手工单据号">
              <el-input :disabled="disabled" class="inputWidth" size="mini" placeholder="请输入手工单据号"
                v-model="form.handBillNo" :maxlength="40">
              </el-input>
            </el-form-item>
            <el-form-item label="磅数">
              <el-input :disabled="disabled" class="inputWidth" size="mini" placeholder="请输入磅数"
                v-model="form.handPointSize" :maxlength="40">
              </el-input>
            </el-form-item>
            <el-form-item label="尺寸">
              <el-input :disabled="disabled" class="inputWidth" size="mini" placeholder="请输入尺寸" v-model="form.handSize"
                :maxlength="40">
              </el-input>
            </el-form-item>
            <el-form-item label="样式">
              <el-input :disabled="disabled" class="inputWidth" size="mini" placeholder="请输入样式" v-model="form.handStyle"
                :maxlength="40">
              </el-input>
            </el-form-item>
            <el-form-item label="会员" prop="vipId">
              <SelectRemote style="width: 250px" v-model="form.vipId" :option="
                  $select({
                    key: 'vip',
                    option: {
                      option: {
                        disabled: disabled,
                        clearable: true,
                        showItem: [
                          {
                            vipId: form.vipId,
                            vipName: form.vipName,
                          },
                        ],
                      },
                    },
                  }).option
                " />
            </el-form-item>
            <el-form-item label="收款方式" prop="payModeId">
              <SelectLocal :disabled="disabled" v-model="form.payModeId" style="width: 250px" :option="{
                  data: playList,
                  value: 'payModeId',
                  label: 'payModeName',
                }" />
            </el-form-item>
            <el-form-item label="收款金额">
              <el-input :disabled="disabled" class="inputWidth" size="mini" v-model="form.billPayMoney" @input="
                  limitInputlength(form.billPayMoney, form, 'billPayMoney')
                " placeholder="收款金额"></el-input>
            </el-form-item>
            <el-form-item label="单据备注" prop="remark">
              <el-input class="inputWidth" :disabled="disabled" v-model="form.remark" type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间" maxlength="80" :autosize="{ minRows: 1, maxRows: 1 }" />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="商品信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <el-table :data="form.billDetailItems" border show-summary :summary-method="getSummaries"
              @cell-mouse-enter="cellMouseEnter" @cell-mouse-leave="cellMouseLeave" :max-height="tableHeight"
              :height="tableHeight">
              <el-table-column align="center" :label="tableCellLabel" width="80">
                <template v-slot="scope">
                  <i v-if="scope.row.hoverRow" :disabled="disabled" @click="row('push', scope.$index)"
                    class="el-icon-circle-plus operatePush"></i>
                  <i v-if="scope.row.hoverRow" :disabled="disabled" @click="row('del', scope.$index)"
                    class="el-icon-remove operateDel"></i>
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column label="商品编码" width="160" align="center" prop="goodsNo">
                <template slot-scope="scope">
                  <SelectRemote size="mini" :disabled="disabled || form.orderType == 1" v-model="scope.row.goodsNo" :option="
                      $select({
                        key: 'listGoods',
                        noEcho: true,
                        option: {
                          option: {
                            noEcho: true,
                            label: 'goodsNo',
                            remoteBody: { shopId: form.orderShopId },
                            showItem: [
                              {
                                goodsId: scope.row.goodsId,
                                goodsName: scope.row.goodsNo,
                              },
                            ],
                            change: (propValue, row) => {
                              if (propValue) {
                                const findIndex =
                                  form.billDetailItems.findIndex(
                                    (item) => item.goodsNo === propValue
                                  );
                                let rows = JSON.parse(JSON.stringify(row));
                                rows.findIndex = findIndex;
                                getGoodS([rows]);
                              }
                            },
                            tableChange: (propValue, row) => {
                              getGoodS(row.check);
                            },
                            buttons: [
                              {
                                type: 'more',
                                option: {
                                  title: '选择商品',
                                  width: 1250,
                                  type: 'TreeAndTable',
                                  formData: $dialog({
                                    key: 'goods',
                                    option: {
                                      queryParams: { shopId: form.orderShopId },
                                      table: {
                                        ...$dialog({ key: 'goods' }).table,
                                      },
                                    },
                                  }),
                                },
                              },
                            ],
                          },
                        },
                      }).option
                    " :ref="'goodsNo' + '_' + scope.$index + '_1'" @handleSelectKeydown="
                      handleTableKeydown(
                        $event,
                        scope.row,
                        'goodsNo',
                        scope.$index,
                        1
                      )
                    " :filterable="true" placeholder="选择商品" />
                </template>
              </el-table-column>

              <el-table-column label="商品名称" align="center" prop="goodsName" width="180">
              </el-table-column>
              <el-table-column label="条码" width="180" align="center" prop="barcode">
              </el-table-column>
              <el-table-column label="规格" align="center" prop="goodsSpec" width="100">
              </el-table-column>
              <el-table-column label="单位" align="center" prop="unitName" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>单位</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'billDetailItems.' + scope.$index + '.unitId'"
                    :rules="rules[`billDetailItems.unitId`]">
                    <el-select size="mini" @change="unitSalePriceChange($event, scope.row, 140314)"
                      v-model="scope.row.unitId" :ref="'unitName' + '_' + scope.$index + '_5'" @keydown.native="
                        handleTableKeydown(
                          $event,
                          scope.row,
                          'unitName',
                          scope.$index,
                          5
                        )
                      " :disabled="disabled || form.orderType == 1">
                      <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                        :value="item.unitId" />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="加值属性" align="center" prop="machining" width="180">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>加值属性</span>
                </template>
                <template slot-scope="scope">
                  <div class="x-bc">
                    <el-button :disabled='disabled' type="primary" size="mini"
                      @click="openMachining(scope.row, scope.$index)">选择</el-button>
                    <el-tooltip class="item" effect="dark" :content="scope.row.machining" placement="top">
                      <div class="omit1">
                        {{scope.row.machining}}
                      </div>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="数量" align="center" width="120" prop="unitQty">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>数量</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item :prop="'billDetailItems.' + scope.$index + '.unitQty'"
                    :rules="rules[`billDetailItems.unitQty`]">
                    <el-input size="mini" type="number" @keyup.native="onkeyupUnitQty(scope.row)"
                      v-model="scope.row.unitQty" :ref="'unitQty' + '_' + scope.$index + '_6'"
                      @keydown.native.up.prevent="
                        handleTableKeydown(
                          $event,
                          scope.row,
                          'unitQty',
                          scope.$index,
                          6
                        )
                      " @keydown.native.down.prevent="
                        handleTableKeydown(
                          $event,
                          scope.row,
                          'unitQty',
                          scope.$index,
                          6
                        )
                      " @keydown.native.left.prevent="
                        handleTableKeydown(
                          $event,
                          scope.row,
                          'unitQty',
                          scope.$index,
                          6
                        )
                      " @keydown.native.right.prevent="
                        handleTableKeydown(
                          $event,
                          scope.row,
                          'unitQty',
                          scope.$index,
                          6
                        )
                      " @keydown.native.enter.prevent="
                        handleTableKeydown(
                          $event,
                          scope.row,
                          'unitQty',
                          scope.$index,
                          6
                        )
                      " :disabled="disabled" maxlength="7" @input="
                        limitInputlength(
                          scope.row.unitQty,
                          scope.row,
                          'unitQty',
                          true
                        )
                      " :sunyunFormat="(v) => $syInput(1, v)"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="原价" align="center" prop="unitOldPrice" width="100" />
              <el-table-column label="原价金额" align="center" prop="unitOldMoney" width="100" />
              <el-table-column label="单价(元)" align="center" width="120" prop="unitPrice">
                <template slot-scope="scope">
                  <el-form-item :prop="scope.$index.unitPrice">
                    <el-input size="mini" type="number" @keyup.native="onkeyupUnitQty(scope.row)"
                      v-model="scope.row.unitPrice" :disabled="disabled" maxlength="7" @input="
                        limitInputlength(
                          scope.row.unitPrice,
                          scope.row,
                          'unitPrice',
                          true
                        )
                      " :sunyunFormat="(v) => $syInput(1, v)"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="金额(元)" align="center" width="120" prop="unitMoney">
                <template slot-scope="scope">
                  <span>{{ $syInput(3, scope.row.unitMoney) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="备注" align="center" prop="remark">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.remark" :disabled="disabled" maxlength="8"
                    :ref="'remark' + '_' + scope.$index + '_9'" @keydown.native="
                      handleTableKeydown(
                        $event,
                        scope.row,
                        'remark',
                        scope.$index,
                        9
                      )
                    "></el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 新增和单独编辑对话框 -->
    <c-dialog title="选择加值属性" percentageWidth="60%" :showDialog.sync="dialogFormVisible">
      <template #content>
        <div>
          <div>选择规格</div>
          <div class="x-w" style="margin-bottom: 20px">
            <div v-for="(item, index) in machiningRow.units" :key="index" class="cursorP itemMachining"
              :class="{ activeMachining: activeIndex == index }" @click="goodsSpecChange(index)">
              {{ `${item.goodsSpec} ${item.salePrice > 0 ? ('￥' + item.salePrice) : ''}` }}
            </div>
          </div>
          <div>
            <div v-for="(item, index) in baseGoodsMachiningGroups" :key="index" style="margin-bottom: 20px">
              <div style="margin-bottom: 10px">
                {{ item.machiningGroupName }}
              </div>
              <div class="x-w" style="margin-bottom: 20px">
                <div v-for="(item2, index2) in item.machiningItems" :key="index2" class="cursorP itemMachining"
                  :class="{ activeMachining: (hasMachiningId(item2.machiningId, radioIds) || hasMachiningId(item2.machiningId, multipleMachiningIds)) }"
                  @click="combinationCheckClick(item2, index, item)">
                  {{ `${item2.machiningName} ${item2.machiningPrice > 0 ? ('￥' + item2.machiningPrice) : ''}` }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #buttonList>
        <el-button @click="resetMach">取 消</el-button>
        <el-button type="primary" @click="submitMach">确 定</el-button>
      </template>
    </c-dialog>
  </el-form>
</template>

<script>
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { listGoods } from "@/api/goods/goods"; //商品列表
import { getAddressId } from "@/views/components/bill/public-layout.js";
import {
  getOrderList,
  updateOrderStatus,
  addOrder,
  getOrderDetail,
  updateOrder,
} from "@/api/shop/bill/reserveOrder";
import { getTimeDate } from "@/utils/newDate"; //到货时间
import { getBillNo } from "@/api/codeRule"; //单据号
import { unitSalePriceChange } from "@/utils/unitChange"; //多级单位切换函数
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
import { getDicts } from "@/api/system/dict/data";
import SelectLocal from "@/components/tablePage/select/select-local/index.vue";
import { shopBasPayList } from "@/api/O2OMall/orderManage/order.js";
import { getAllRegion } from "@/api/goods/region";
import LD from "lodash";
import tableKeydown from "@/utils/mixin/tableKeydown";
import { limitInputlength, fcount, isNumber, deepCopy } from "@/utils";
import { includes } from 'xe-utils';
export default {
  name: "materialOrderDetail",
  mixins: [tableKeydown],
  components: {
    topOperatingButton,
    cardTitleCom,
    SelectRemote,
    SelectLocal,
    CDialog,
  },
  data() {
    return {
      playListCopy: [], //复制的收款方式列表
      multipleMachiningIds: [], //加值属性多选Id数组
      radioIds: [], //加值属性单选Id数组
      activeIndex: 0, //加值属性选中索引
      machiningRow: {}, //加值属性选中行
      machiningIndex: 0, //加值属性选中行索引
      dialogFormVisible: false, //选择加值属性对话框
      forbiddenTime: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      defaultProp: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      //省市区
      selectedOptions: [],
      //全部行政地区
      provinceList: [],
      tableCellLabel: "序号",
      showAudit: true, //审核
      //遮罩层
      loading: false,
      //标识已审核
      disabled: false,
      //终止
      single: true,
      //新增按钮
      showAdd: false,
      fetchTimeList: [
        { value: "00:00-00:30" },
        { value: "00:30-01:00" },
        { value: "01:00-01:30" },
        { value: "01:30-02:00" },
        { value: "02:00-02:30" },
        { value: "02:30-03:00" },
        { value: "03:00-03:30" },
        { value: "03:30-04:00" },
        { value: "04:00-04:30" },
        { value: "04:30-05:00" },
        { value: "05:00-05:30" },
        { value: "05:30-06:00" },
        { value: "06:00-06:30" },
        { value: "06:30-07:00" },
        { value: "07:00-07:30" },
        { value: "07:30-08:00" },
        { value: "08:00-08:30" },
        { value: "08:30-09:00" },
        { value: "09:00-09:30" },
        { value: "09:30-10:00" },
        { value: "10:00-10:30" },
        { value: "10:30-11:00" },
        { value: "11:00-11:30" },
        { value: "11:30-12:00" },
        { value: "12:00-12:30" },
        { value: "12:30-13:00" },
        { value: "13:00-13:30" },
        { value: "13:30-14:00" },
        { value: "14:00-14:30" },
        { value: "14:30-15:00" },
        { value: "15:00-15:30" },
        { value: "15:30-16:00" },
        { value: "16:00-16:30" },
        { value: "16:30-17:00" },
        { value: "17:00-17:30" },
        { value: "17:30-18:00" },
        { value: "18:00-18:30" },
        { value: "18:30-19:00" },
        { value: "19:00-19:30" },
        { value: "19:30-20:00" },
        { value: "20:00-20:30" },
        { value: "20:30-21:00" },
        { value: "21:00-21:30" },
        { value: "21:30-22:00" },
        { value: "22:00-22:30" },
        { value: "22:30-23:00" },
        { value: "23:00-23:30" },
        { value: "23:30-00:00" },
      ],
      oldForm: {}, //旧的表单
      //表单数据
      form: {
        orderShopId: null, //预定门店
        billNo: null, //单据编号
        billDate: null, //单据时间
        fetchShopId: null, //取货/配送门店
        fetchType: "1", //取货方式
        fetchDate: null, //取货时间
        fetchTime: null, //取货时间
        receiverName: null, //客户名称
        receiverPhone: null, //联系电话
        receiverAddress: null, //地址
        receiverRegionAddress: null, //详细地址
        produceDeptId: -1, //生产部门
        deliveryMode: null, //配送方式
        payModeId: null, //收款方式
        remark: null, //单据备注
        receiverRegionId: null,
        //单据表格-订单明细
        billDetailItems: [{ hoverRow: false }],
      },
      //表单校验
      rules: {
        billNo: [
          {
            required: true,
            message: "请配置编码规则",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [
          {
            required: true,
            message: "请选择单据时间",
            trigger: ["blur", "change"],
          },
        ],
        orderShopId: [
          {
            required: true,
            message: "请选择预定门店",
            trigger: ["blur", "change"],
          },
        ],
        fetchShopId: [
          {
            required: true,
            message: "请选择配送门店",
            trigger: ["blur", "change"],
          },
        ],
        fetchType: [
          {
            required: true,
            message: "请选择提货方式",
            trigger: ["blur", "change"],
          },
        ],
        produceDeptId: [
          {
            required: true,
            message: "请选择生产部门",
            trigger: ["blur", "change"],
          },
        ],
        receiverName: [
          {
            required: true,
            message: "请输入客户名称",
            trigger: ["blur", "change"],
          },
        ],
        receiverPhone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: ["blur", "change"],
          },
        ],
        fetchDate: [
          {
            required: true,
            message: "请输入取货/配送时间",
            trigger: ["blur", "change"],
          },
        ],
        fetchTime: [
          {
            required: true,
            message: "请输入取货/配送时间段",
            trigger: ["blur", "change"],
          },
        ],
        receiverRegionId: [
          {
            required: true,
            message: "请输入地址",
            trigger: ["blur", "change"],
          },
        ],
        receiverRegionAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["blur", "change"],
          },
        ],
        "billDetailItems.unitId": [
          {
            required: true,
            message: "单位不能为空",
            trigger: ["blur", "change"],
          },
        ],
        "billDetailItems.unitQty": [
          {
            required: true,
            message: "数量不能为空",
            trigger: ["blur"],
          },
          {
            pattern: /^([1-9]\d*|0\.\d*[1-9]\d*|[1-9]\d*\.\d*[1-9]\d*)$/,
            message: "数值类型且不为0",
            trigger: ["blur"],
          },
        ],
      },
      vivwH: 0, //页面高度
      topOperatingH: 0, //顶部按钮高度
      basicH: 0, //基本信息
      playList: [],
      produceSelectList: [],
      canKeyTableColumnList: [
        { key: "goodsNo", columnindex: 1, componentRefName: "remoteSelect" },
        { key: "goodsSpec", columnindex: 4, type: "select" },
        { key: "unitName", columnindex: 5, type: "select" },
        { key: "unitQty", columnindex: 6 },
        { key: "remark", columnindex: 9 },
      ], //可以键盘导航的表格列详情
    };
  },
  watch: {
    //侦听是否开启关闭按钮
    "form.orderStatus": {
      handler(newVal) {
        if (this.form.orderStatus == 1) {
          this.form.billStatus = "0";
          //新增按钮
          this.showAdd = false;
          //打开输入按钮、保存按钮
          this.disabled = false;
          //禁用关闭按钮
          this.single = true;
          //禁用审核按钮
          this.showAudit = false;
        } else if (this.form.orderStatus >= 5 && this.form.orderStatus < 7) {
          this.form.billStatus = "2";
          //新增按钮
          this.showAdd = true;
          //打开关闭按钮
          this.single = false;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = true;
        } else if (this.form.orderStatus >= 0) {
          this.form.billStatus = "2";
          //新增按钮
          this.showAdd = true;
          //打开关闭按钮
          this.single = true;
          //禁用输入按钮、保存按钮
          this.disabled = true;
          //打开审核按钮
          this.showAudit = true;
        } else {
          this.form.billStatus = "";
          //新增按钮
          this.showAdd = false;
          //禁用审核按钮
          this.showAudit = true;
          //禁用关闭按钮
          this.single = true;
          //打开输入按钮、保存按钮
          this.disabled = false;
        }
      },
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.loading = true;
      await vm.reset();
      // 访问组件实例 vm 中的数据和方法
      if (vm.$route.query.type === "Update") {
        if (vm.$route.query.billId) {
          //获取单据详情
          const res1 = await getOrderDetail(vm.$route.query.billId);
          //单据赋值
          vm.conversion(res1.data);
          //原始单据赋值
          vm.oldForm = JSON.parse(JSON.stringify(vm.form));
        }
      } else {
        vm.disabled = false;
        vm.playList = vm.playListCopy.filter(
          (i) => i.payModeId > 16 || i.payModeId == 1 || i.payModeId == 3 || i.payModeId == 6 || i.payModeId == 7 || i.payModeId == 8
        );
        //判断是否有以前新增时保存的数据
        if (JSON.stringify(vm.oldForm) != "{}") {
          vm.form = vm.oldForm;
        }
        vm.oldForm = JSON.parse(JSON.stringify(vm.form));
      }
      vm.loading = false;
    });
  },

  async beforeRouteLeave(to, from, next) {
    // 在用户离开二级路由前弹出询问框
    if (this.form.billId) {
      this.oldForm = {};
    } else {
      this.oldForm = JSON.parse(JSON.stringify(this.form));
    }
    next();
  },
  computed: {
    baseGoodsMachiningGroups() {
      return (
        this?.machiningRow?.units?.[this.activeIndex]
          ?.baseGoodsMachiningGroups || []
      );
    },
    tableHeight() {
      return this.vivwH - this.topOperatingH - this.basicH - 62;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.topOperatingH =
        document.getElementById("topOperatingButton").clientHeight;
      this.basicH = document.getElementById("basicInformation").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  async created() {
    this.getRegion();
  },
  methods: {
    hasMachiningId(machiningId, idsArray) {
      return idsArray.some(idObj => idObj.machiningId === machiningId);
    },
    submitMach() {
      let isReadio = false;
      let isReadioIndex = 0;
      const rules = [...this.radioIds, ...this.multipleMachiningIds];
      if (this.baseGoodsMachiningGroups.length) {
        for (const [i, v] of this.baseGoodsMachiningGroups.entries()) {
          if (v.isRequired) {
            const machiningIdList = v.machiningItems.map((i) => i.machiningId)
            const result = rules.filter(item => machiningIdList.includes(item.machiningId));
            if (!result.length) {
              isReadio = true;
              isReadioIndex = i
              break;
            }
          }
        }
      }
      if (isReadio) {
        let text = this.baseGoodsMachiningGroups[isReadioIndex].machiningGroupName;
        return this.$message.error(`请选择${text}`);
      }
      let row = deepCopy(this.form.billDetailItems[this.machiningIndex])
      row.salePrice = row.units[this.activeIndex].salePrice
      row.goodsSpec = row.units[this.activeIndex].goodsSpec
      row.unitId = row.units[this.activeIndex].unitId
      row.machining = rules.map(v => v.machiningName).join(',')
      row.machiningIds = rules.map(v => v.machiningId).join(',');
      row.multipleMachiningIds = this.multipleMachiningIds;
      row.radioIds = this.radioIds;
      row.machiningMoney = rules.map(v => v.machiningPrice).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      row.unitPrice = fcount([row.machiningMoney, row.salePrice], '+')
      row.unitOldPrice = fcount([row.machiningMoney, row.salePrice], '+')
      row.unitMoney = fcount([row.unitQty, row.unitPrice], "*");
      row.unitOldMoney = fcount([row.unitQty, row.unitOldPrice], "*");
      this.$set(this.form.billDetailItems, this.machiningIndex, deepCopy(row))
      const billPayMoney = this.form.billDetailItems.reduce((total, item) => fcount([total, item.unitMoney], '+'), 0);
      this.$set(this.form, 'billPayMoney', billPayMoney)
      this.dialogFormVisible = false;
    },
    resetMach() {
      this.dialogFormVisible = false;
      this.multipleMachiningIds = [];
      this.radioIds = [];
    },
    goodsSpecChange(index) {
      this.activeIndex = index;
      this.multipleMachiningIds = [];
      this.radioIds = [];
    },
    //组合规格属性点击
    combinationCheckClick(value, index1, item) {
      if (item.isEnableCheckBox) {
        this.checkClick(value)
      } else {
        this.radioClick(value, index1)
      }
    },
    // 多选属性点击
    checkClick(value) {
      if (this.multipleMachiningIds.some(x => x.machiningId === value.machiningId)) {
        this.multipleMachiningIds.splice(this.multipleMachiningIds.findIndex(x => x.machiningId === value.machiningId), 1);
      } else {
        this.multipleMachiningIds.push({ machiningId: value.machiningId, machiningName: `${value.machiningName} ${value.machiningPrice > 0 ? ('￥' + value.machiningPrice) : ''}`, machiningPrice: value.machiningPrice });
      }
    },
    // 单选属性点击
    radioClick(value, index1) {
      if (this.radioIds.some(x => x.machiningId === value.machiningId)) {
        this.radioIds.splice(this.radioIds.findIndex(x => x.machiningId === value.machiningId), 1);
      } else {
        const machiningIdList = this.baseGoodsMachiningGroups[index1].machiningItems.map((i) => i.machiningId)
        this.radioIds = this.radioIds.filter(item => !machiningIdList.includes(item.machiningId));
        this.radioIds.push({ machiningId: value.machiningId, machiningName: `${value.machiningName} ${value.machiningPrice > 0 ? ('￥' + value.machiningPrice) : ''}`, machiningPrice: value.machiningPrice });
      }
    },
    openMachining(row, index) {
      this.machiningRow = row;
      this.machiningIndex = index;
      this.activeIndex = row.units.findIndex(x => x.unitId === row.unitId)
      const machiningIdsArray = row?.machiningIds?.split(',') || [];
      const baseGoodsMachiningGroups = row.units?.[this.activeIndex]?.baseGoodsMachiningGroups || [];
      const checkBoxs = baseGoodsMachiningGroups.filter(x => x.isEnableCheckBox).map(x => x.machiningItems).flat()
      const checkBox = baseGoodsMachiningGroups.filter(x => !x.isEnableCheckBox).map(x => x.machiningItems).flat()
      this.multipleMachiningIds = checkBoxs.filter(x => machiningIdsArray.includes(x.machiningId));
      this.radioIds = checkBox.filter(x => machiningIdsArray.includes(x.machiningId)) || [];
      this.dialogFormVisible = true;
    },
    async selectChangeOrderShop(val) {
      let res = await listGoods({
        pageNum: 1,
        pageSize: 1000,
        shopId: val,
        goodsIds: this.form.billDetailItems.map((item) => item.goodsId),
      });
      this.form.billDetailItems = this.form.billDetailItems.map((item) => {
        let item1 = res.rows.find((item2) => item2.goodsId === item.goodsId);
        if (item1) {
          item1.units.forEach((v) => {
            if (v.unitId === item.unitId) {
              this.$set(item1, "unitPrice", v.salePrice);
              this.$set(item1, "unitOldPrice", v.salePrice);
            }
          });
          const unitMoney = fcount([item.unitQty, item1.unitPrice], "*");
          const unitOldMoney = fcount([item.unitQty, item1.unitOldPrice], "*");
          return {
            ...item,
            units: item1.units,
            unitPrice: item1.unitPrice,
            unitOldPrice: item1.unitOldPrice,
            unitMoney,
            unitOldMoney,
            machiningMoney: null,
            machining: null,
            machiningIds: null,
          };
        } else {
          return item;
        }
      });
      const billPayMoney = this.form.billDetailItems.reduce((total, item) => fcount([total, item.unitMoney], '+'), 0);
      this.$set(this.form, 'billPayMoney', billPayMoney)
    },
    conversion(form = {}) {
      let {
        billOrderReq,
        billNo,
        billId,
        billDate,
        billOrderListReservePayDetailReqs,
        billStatus,
        vipId,
        orderStatus,
        billOrderListReserveDetailReqs,
      } = form;
      this.form = {
        ...form,
        ...form.billOrderReq,
        billNo,
        billDate,
        billId,
        deliveryMode: billOrderReq?.deliveryMode
          ? billOrderReq?.deliveryMode?.toString()
          : "",
        payModeId: billOrderListReservePayDetailReqs?.[0]?.payModeId,
        billStatus,
        billPayMoney: billOrderListReservePayDetailReqs?.[0]?.payMoney,
        vipId,
        orderStatus,
        billDetailItems: billOrderListReserveDetailReqs,
        billStatus: this.form.billStatus
      };
      if ((this.form.orderStatus == 1 || !this.form.orderStatus) && this.form?.orderType != 1) {
        this.playList = this.playListCopy.filter(
          (i) => i.payModeId > 16 || i.payModeId == 1 || i.payModeId == 3 || i.payModeId == 6 || i.payModeId == 7 || i.payModeId == 8
        );
      } else {
        this.playList = this.playListCopy.filter(
          (i) => [3, 8].includes(i.payModeId)
        );
      }

    },
    //获取全部行政地区事件
    getRegion() {
      getAllRegion().then((response) => {
        this.provinceList = response.data;
      });
    },
    limitInputlength,
    getAddressId,
    changeProduceGroupChange(v) {
      // this.$set(this.form, "produceOrderId", undefined);
      // this.listReserveOrder.remoteBody = { produceDeptId: v };
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.topOperatingH =
        document.getElementById("topOperatingButton").clientHeight;
      this.basicH = document.getElementById("basicInformation").clientHeight;
    },
    unitSalePriceChange,
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (this.disabled) return;
      let eleIndex = this.form.billDetailItems.indexOf(row);
      if (eleIndex >= 0) {
        this.form.billDetailItems[eleIndex].hoverRow = true;
        this.tableCellLabel = "操作";
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      if (this.disabled) return;
      // 移除hover的事件
      for (let index = 0; index < this.form.billDetailItems.length; index++) {
        const element = this.form.billDetailItems[index];
        element["hoverRow"] = false;
      }
      this.tableCellLabel = "序号";
    },
    //退出
    getQuit() {
      this.$tab.closeOpenPage({ path: "/shop/bill/reserveOrder" });
    },
    //新增按钮
    async handleAdd() {
      this.loading = true;
      this.reset();
      this.loading = false;
    },
    //数量键盘抬起
    onkeyupUnitQty(row) {
      const unitMoney = fcount([row.unitQty, row.unitPrice], "*");
      const unitOldMoney = fcount([row.unitQty, row.unitOldPrice], "*");
      this.$set(row, "unitMoney", unitMoney);
      this.$set(row, "unitOldMoney", unitOldMoney);
      const billPayMoney = this.form.billDetailItems.reduce((total, item) => fcount([total, item.unitMoney], '+'), 0);
      this.$set(this.form, 'billPayMoney', billPayMoney)
    },
    //表单重置
    async reset() {
      //表单数据
      this.form = {
        orderShopId: null, //预定门店
        billNo: null, //单据编号
        billDate: null, //单据时间
        fetchShopId: null, //取货/配送门店
        fetchType: "1", //取货方式
        fetchDate: null, //取货时间
        fetchTime: null, //取货时间
        receiverName: null, //客户名称
        receiverPhone: null, //联系电话
        receiverAddress: null, //地址
        receiverRegionAddress: null, //详细地址
        produceDeptId: -1, //生产部门
        deliveryMode: null, //配送方式
        payModeId: null, //收款方式
        billPayMoney: null, //收款金额
        remark: null, //单据备注
        //单据表格-订单明细
        billDetailItems: [{ hoverRow: false }],
      };
      this.resetForm("form");
      let { rows } = await shopBasPayList({
        isUse: true,
        pageNum: 1,
        pageSize: 999,
      });
      this.playListCopy = deepCopy(rows);
      let { data } = await getOrderList({});
      this.produceSelectList = data;
      this.produceSelectList.unshift({
        decorationCaseId: "-1",
        decorationRoomId: -1,
        decorationRoomName: "系统自动匹配",
      });
      await this.getBasicData();
    },
    //初始化页面
    async getBasicData() {
      this.form.billDate = getTimeDate();
      const res2 = await getBillNo(140314);
      this.form.billNo = res2;
    },
    //保存并新增单据
    async addBill() {
      try {
        this.loading = true;
        await this.submitForm(false);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    //订单明细表格合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const other = ["unitQty", "unitOldMoney", "unitMoney"];
        const [key] = column.property?.split?.(".") || [];
        if (other.includes(column.property) || other.includes(key)) {
          let values = data.map((x) => {
            let num = Number(x[column.property] || 0);
            return num;
          });
          sums[index] = values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], "+"),
            0
          );
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    //选中的商品
    getGoodS(value) {
      if (!this.form.orderShopId) {
        this.$message.warning("添加单据明细之前, 请先选择预定门店！");
        this.form.billDetailItems = [{ hoverRow: false }];
        return;
      }
      let rows = JSON.parse(JSON.stringify(value));
      rows.forEach((v) => {
        this.$set(v, "unitQty", "");
        this.$set(v, "unitPrice", v.salePrice);
        this.$set(v, "unitOldPrice", v.salePrice);
        this.$set(v, "unitOldMoney", fcount([v.unitQty, v.unitOldPrice], "*"));
        this.$set(v, "unitMoney", fcount([v.unitQty, v.unitPrice], "*"));
        v.remark = "";
        v.hoverRow = false;
      });
      let findIndex = rows[0].findIndex;
      if (rows.length == 1 && findIndex != undefined) {
        console.log(rows[findIndex]);
        this.form.billDetailItems[findIndex] = rows[0];
      } else {
        this.form.billDetailItems.push(...rows);
      }
      this.form.billDetailItems = this.form.billDetailItems.filter(
        (item) => item.goodsNo != "" && item.goodsNo != undefined
      );
      if (this.form.billDetailItems.length == 0) {
        this.form.billDetailItems = [{ hoverRow: false }];
      }
    },
    //表格增加/减少一行方法
    row(name, index) {
      if (this.disabled || this.form.orderType == 1) return;
      if (name === "push") {
        this.form.billDetailItems.splice(index + 1, 0, {
          goodsNo: "",
          hoverRow: false,
        });
      } else {
        if (this.form.billDetailItems.length <= 1) {
          this.form.billDetailItems = [{ hoverRow: false }];
        } else {
          this.form.billDetailItems.splice(index, 1);
        }
      }
    },
    //审核单据方法
    async auditBill(name) {
      try {
        await this.$confirm(`确定${name}该订单, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });

        if (name === "审核") {
          if (this.form.orderStatus != 1) {
            return this.$message.error("当前状态不允许进行审核操作");
          } else if (!this.form.payModeId) {
            return this.$message.error("请选择收款方式");
          } else if (!this.form.billPayMoney && this.form.billPayMoney !== 0) {
            return this.$message.error("收款金额不能为空");
          }

          const obj = {
            billIds: [this.form.billId],
            billStatus: 2,
            billType: 140314,
          };
          await this.submitForm(true);
          //发送审核api
          const res = await updateOrderStatus(obj);
          this.conversion(res.data);
          // 弹出提示
          this.$message.success("审核成功");
          //禁用全部输入
          this.disabled = true;
        } else if (name === "终止") {
          if (["5", "6", "7", 5, 6, 7].includes(this.form.orderStatus)) {
            return this.$message.error("当前状态不允许进行终止操作");
          }
          const obj = {
            billIds: [this.form.billId],
            billStatus: 4,
            billType: 140314,
          };
          //发送审核api
          const res = await updateOrderStatus(obj);
          this.conversion(res.data);
          // 弹出提示
          this.$message.success("终止成功");
        }
      } catch { }
    },
    getNodes() {
      const checkedNodes = this.$refs["cascader"].getCheckedNodes();
      if (checkedNodes && checkedNodes[0]) {
        const pathLabels = checkedNodes[0].pathLabels;
        console.log("receiverAddress1", this.form.receiverAddress);
        this.form.receiverAddress =
          (pathLabels[0] || "") + (pathLabels[1] || "") + (pathLabels[2] || "");
        console.log("receiverAddress2", this.form.receiverAddress);
      } else {
        this.form.receiverAddress = null;
      }
    },
    futterCount() {
      LD.map(this.form.billDetailItems, (item, index) => {
        item.unitCostPrice = item.purPrice;
        item.unitSalePrice = item.unitSalePrice || item.salePrice;
        item.unitPurPrice = item.purPrice;
        item.unitSaleMoney = item.unitSalePrice * item.unitQty;
        item.unitOldMoney = fcount([item.unitOldPrice, item.unitQty], "*");
        item.basUnitPrice = item.unitPrice / item.units[0].basUnitAmount;
        item.basUnitSalePrice = item.units[0].salePrice;
        item.basUnitPurPrice = item.units[0].purPrice;
        item.basUnitQty = item.unitQty * item.units[0].basUnitAmount;
        item.basUnitAmount = item.units[0].basUnitAmount;
        item.agioMoney = fcount([item.unitOldMoney, item.unitMoney], "-");
        item.agioType = item.agioType && (this.form.orderType == 1 && item.unitOldMoney == item.unitPriceOld) ? item.agioType : item.agioMoney ? 52 : 0;
        item.agioRate = item.agioMoney ? fcount([item.unitMoney, item.unitOldMoney], "-") : 1;
        item.lineType = item.lineType || 0;
      });
    },
    /** 提交按钮 */
    async submitForm(isBool) {
      if (this.form.payModeId && !this.form.billPayMoney && this.form.billPayMoney !== 0) return this.$message.error("收款金额不能为空");
      if (!this.form.payModeId && this.form.billPayMoney) return this.$message.error("请选择收款方式");
      if (this.form.payModeId == 3 && !this.form.vipId) return this.$message.error("请选择会员");
      if (
        !(JSON.stringify(this.form.billDetailItems[0]) === "{}") &&
        !(this.form.billDetailItems[0].goodsId === undefined)
      ) {
        const valid = await this.$refs.form.validate();
        await this.futterCount();
        const billOrderListReserveDetailReqs = this.form.billDetailItems;
        const playObj = this.playList?.find((obj) => this.form.payModeId == obj.payModeId) || {};
        const receiverAddress = (this.form.receiverAddress || "") + (this.form.receiverRegionAddress || "");
        const fetchDate = this.form.fetchDate + " 00:00:00";
        const billTimeStr = this.form.billDate.split(" ")[1];
        const billDateStr = this.form.billDate.split(" ")[0];

        const params = {
          ...this.form,
          billOrderReq: {
            ...this.form,
            billSubType: 0,
            retailType: 0,
            billPosNo: this.form.billNo,
            billQty: this.form.billDetailItems.length,
            billDetailItems: null,
            receiverRegionId: this.form.receiverRegionId,
            fetchDate,
            receiverAddress,
            billDiscountMoney: billOrderListReserveDetailReqs.reduce((sum, obj) => fcount([sum, obj.agioMoney], '+'), 0),
            orderDiscountMoney: billOrderListReserveDetailReqs.reduce((sum, obj) => fcount([sum, obj.agioMoney], '+'), 0),
            billOrderReq: null,
            billOrderListReserveDetailReqs: null,
            billOrderListReservePayDetailReqs: null,
          },
          billOrderListReserveDetailReqs,
          billOrderListReservePayDetailReqs: playObj.payModeId ? [{
            payModeId: playObj.payModeId,
            payModeName: playObj.payModeName,
            vipId: this.form.vipId,
            payMoney: this.form.billPayMoney,
          }] : [],
          billCustomType: 4,
          fetchDate,
          billTimeStr,
          billDateStr,
          receiverAddress,
          shopId: this.form.orderShopId,
          receiverRegionId: this.form.receiverRegionId,
          billSaleMoney: billOrderListReserveDetailReqs.reduce((sum, obj) => sum + obj.unitSaleMoney, 0),
          billFactMoney: billOrderListReserveDetailReqs.reduce((sum, obj) => fcount([sum, obj.unitMoney], '+'), 0),
          billMoney: billOrderListReserveDetailReqs.reduce((sum, obj) => fcount([sum, obj.unitMoney], '+'), 0),
          billOldMoney: billOrderListReserveDetailReqs.reduce((sum, obj) => fcount([sum, obj.unitOldMoney], '+'), 0),
          billDiscountMoney: billOrderListReserveDetailReqs.reduce((sum, obj) => fcount([sum, obj.agioMoney], '+'), 0),
          billDetailItems: null,
        };
        if (valid) {
          const res = this.form.billId ? await updateOrder(params) : await addOrder(params);
          this.conversion(res.data);
          const successMessage = this.form.billId ?
            (isBool ? "修改单据成功" : "保存并新增单据成功") :
            (isBool ? "新增单据成功" : "保存并新增单据成功");
          if (!isBool) await this.reset();
          this.$message.success(successMessage);
        }
      } else {
        this.$message.error("单据明细不能为空");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 10px;
}
//整体盒子
.wrap {
  padding: 40px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);

  .headGoodsTable {
    //商品表格卡片
    .goodsMessage {
      padding: 6px 10px;
      ::v-deep .el-form-item {
        margin: 0;
        padding: 0;
      }
      ::v-deep .el-form-item__content {
        margin-left: 0 !important;
      }

      ::v-deep .el-form-item__error {
        position: static;
      }
    }
  }
}

//输入框原生属性
/**
   * 解决el-input设置类型为number时，中文输入法光标上移问题
   **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
   * 解决el-input设置类型为number时，去掉输入框后面上下箭头
   **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemMachining {
  padding: 8px 15px;
  background-color: #eeeeee;
  border-radius: 8px;
  margin: 10px 10px 0 0;
  border: 1px solid #eeeeee;
}
.activeMachining {
  border: 1px solid #409eff;
  background-color: #d5eaff;
}
</style>
